// 
// 
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.
$breakpoint-tablet: 992px;

.gro-landing-cover {
    background-image: url('../img/gro-landing.png');
    height: 80vh;
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
}

@media (max-width: $breakpoint-tablet) {
    .gro-landing-cover {
        background-image: none;
        background-color: #BCCEFF !important;
    }
}

.particle-style {
    position: absolute;
    background: linear-gradient(90deg, rgb(0, 153, 255) 0%, rgb(102, 16, 242) 100%);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    opacity: 0.5;
}

.banner-content {
    z-index: 20;
}

.gro-logo {
    height: 50px;
}

// Nav link
.nav-link.h5 {
    font-size: 1rem;
}

.gro-social.list-social-links a {
    color: $blue !important;
    // background-color: transparent;
}

.gro-footer {
    .nav-link {
        color: $white;
        &:hover {
            color: $blue;
        }
    }
}

.gro-grey-card {

}

// Vertical Timeline
.v-timeline {
    .icon-rounded {
        width: 2.5rem;
        height: 2.5rem;
    }
    .liner-icon {
        z-index: 200;
    }
    .liner-btm {
        content: ' ';
        position: absolute;
        top: 50%;
        bottom: 0;
        background: rgb(233, 236, 239);
        width: 4px;
        z-index: 100;
    }
    .liner-top {
        content: ' ';
        position: absolute;
        top: 0;
        bottom: 50%;
        background: rgb(233, 236, 239);
        width: 4px;
        z-index: 100;
    }
    .liner-full {
        content: ' ';
        position: absolute;
        top: 0;
        bottom: 0;
        background: rgb(233, 236, 239);
        width: 4px;
        z-index: 100;
    }
}


// Timeline
.gro-b-border {
    height: 1px;
    // background-color: linear-gradient(90deg, #0099FF 0%, #6610f2 100%);
    background-image: linear-gradient(90deg, rgba(255,255,255,0), rgba(255,255,255,1), rgba(255,255,255,1), rgba(255,255,255,0)) !important;
    width: 100%;
    position: absolute;
    bottom: 24px;
    z-index: -10;
}
.gro-relative {
    position: relative;
}

canvas{ display: block; vertical-align: bottom; }
/* ---- particles.js container ---- */
#particles-js{ position:absolute; width: 100%; height: 100%; background-color: transparent; background-image: url(""); background-repeat: no-repeat; background-size: cover; background-position: 50% 50%; }
/* ---- stats.js ---- */
.count-particles{ background: #000022; position: absolute; top: 48px; left: 0; width: 80px; color: #13E8E9; font-size: .8em; text-align: left; text-indent: 4px; line-height: 14px; padding-bottom: 2px; font-family: Helvetica, Arial, sans-serif; font-weight: bold; }
.js-count-particles{ font-size: 1.1em; }
#stats, .count-particles{ -webkit-user-select: none; margin-top: 5px; margin-left: 5px; }
#stats{ border-radius: 3px 3px 0 0; overflow: hidden; }
.count-particles{ border-radius: 0 0 3px 3px; }